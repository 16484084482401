var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.procensando_datos)?_c('div',[_c('loader',{attrs:{"object":"#4caf50","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"40","disableScrolling":"false","name":"box"}})],1):_vm._e(),_c('v-row',{staticClass:"my-5 mx-3"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"my-5 border pa-4",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"body-2 font-weight-bold",staticStyle:{"font-family":"'Montserrat' !important"}},[_vm._v(" Archivo actualización de plan de cuotas ")]),_c('v-form',{ref:"form",staticClass:"mx-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-file-input',{staticClass:"mb-5",attrs:{"rules":_vm.inputRules,"label":"Nombre del Archivo","show-size":"","accept":".csv"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.backoffice_file),callback:function ($$v) {_vm.backoffice_file=$$v},expression:"backoffice_file"}}),_c('v-select',{staticClass:"mb-8",attrs:{"items":_vm.plan_cuotas,"item-text":"nombre","item-value":"id","label":"Installment","persistent-hint":"","return-object":""},on:{"change":_vm.changeInstallment},model:{value:(_vm.installment),callback:function ($$v) {_vm.installment=$$v},expression:"installment"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha Vigencia Desde","prepend-icon":"today","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"min":_vm.fechaMinima,"no-title":"","dark":"","show-current":"false","locale":"es-es"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold",staticStyle:{"font-family":"'Montserrat'"},attrs:{"disabled":!_vm.valid,"color":"green darken-1","text":""},on:{"click":function($event){return _vm.enviar_file()}}},[_vm._v(" Enviar ")])],1),_c('v-snackbar',{attrs:{"timeout":2000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-layout',{attrs:{"align-center":"","pr-4":""}},[_c('v-icon',{staticClass:"pr-3",attrs:{"dark":"","large":""}},[_vm._v(" file_upload_off ")]),_c('h5',{attrs:{"align":"center"}},[_vm._v("Seleccione un archivo válido por favor")])],1)],1)],1)],1),_c('v-col',{staticClass:"border  rounded px-5 pt-7"},[_c('p',{staticClass:"body-2 font-weight-bold text-start",staticStyle:{"font-family":"'Montserrat' !important"}},[_vm._v(" Todos los planes de cuotas ")]),_c('v-data-table',{staticClass:"elevation-0 mt-5",attrs:{"headers":_vm.headers,"items":_vm.plan_cuotas_info,"no-data-text":"No se encontraron registros","disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.vigencia_desde",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.vigencia_desde))+" ")]}},{key:"item.vigencia_hasta",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vigencia_hasta ? _vm.formatDate(item.vigencia_hasta) : "-")+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }