import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const Archivos = {
  // subir archivos para conciliacion pagos offline hacia la Pasarela
  uploadFile(payload) {
    return axios.post(PASARELA + "/administracion/download-prisma-minio", payload)
  },
  uploadCsvInstallments(payload) {
    return axios.post(PASARELA + "/administracion/download-plan-cuotas", payload)
  },
  getArchivosSubidos(params) {
    return axios.get(PASARELA + "/administracion/archivos-subidos", { params })

  }
}