<template>
  <div>
    <div v-if="procensando_datos">
      <loader
        object="#4caf50"
        color1="#ffffff"
        color2="#17fd3d"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="40"
        disableScrolling="false"
        name="box"
      ></loader>
    </div>
    <v-row class="my-5 mx-3">
      <v-col cols="12" lg="6">
        <v-card elevation="0" class="my-5 border pa-4">
          <v-card-title
            class="body-2 font-weight-bold"
            style="font-family: 'Montserrat' !important;"
          >
            Archivo actualización de plan de cuotas
          </v-card-title>

          <v-form ref="form" v-model="valid" lazy-validation class="mx-4">
            <v-file-input
              v-model="backoffice_file"
              :rules="inputRules"
              label="Nombre del Archivo"
              show-size
              accept=".csv"
              class="mb-5"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>

            <v-select
              v-model="installment"
              :items="plan_cuotas"
              item-text="nombre"
              item-value="id"
              label="Installment"
              persistent-hint
              return-object
              class="mb-8"
              @change="changeInstallment"
            ></v-select>

            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Fecha Vigencia Desde"
                  prepend-icon="today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu2 = false"
                :min="fechaMinima"
                no-title
                dark
                show-current="false"
                locale="es-es"
              ></v-date-picker>
            </v-menu>
          </v-form>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="green darken-1"
              class="font-weight-bold"
              style="font-family: 'Montserrat';"
              text
              @click="enviar_file()"
            >
              Enviar
            </v-btn>
          </v-card-actions>

          <v-snackbar v-model="snackbar" :timeout="2000">
            <v-layout align-center pr-4>
              <v-icon class="pr-3" dark large> file_upload_off </v-icon>
              <h5 align="center">Seleccione un archivo válido por favor</h5>
            </v-layout>
          </v-snackbar>
        </v-card>
      </v-col>

      <v-col class="border  rounded px-5 pt-7">
        <p
          class="body-2 font-weight-bold text-start"
          style="font-family: 'Montserrat' !important;"
        >
          Todos los planes de cuotas
        </p>

        <v-data-table
          :headers="headers"
          :items="plan_cuotas_info"
          class="elevation-0 mt-5"
          no-data-text="No se encontraron registros"
          disable-sort
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.vigencia_desde`]="{ item }">
            {{ formatDate(item.vigencia_desde) }}
          </template>

          <template v-slot:[`item.vigencia_hasta`]="{ item }">
            {{ item.vigencia_hasta ? formatDate(item.vigencia_hasta) : "-" }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Archivos } from "@/services/archivos.js";
import { PlanCuotas } from "@/services/plancuotas.js";
import formatDate from "../../utils/formatDate";
import moment from "moment";

export default {
  name: "ArchivosInstallments",
  data: () => ({
    medio_installment_id: 1,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Descripcion", value: "descripcion" },
      { text: "Emisor", value: "issuer" },
      { text: "Vigencia desde", value: "vigencia_desde" },
      { text: "Vigencia hasta", value: "vigencia_hasta" },
    ],
    fechaMinima: new Date().toISOString().substr(0, 10),
    snackbar: false,
    menu2: false,
    date: moment().format("YYYY-MM-DD"),
    installment: {},
    plan_cuotas: [],
    plan_cuotas_info: [],
    valid: false,
    procensando_datos: false,
    backoffice_file: null,
    inputRules: [
      (value) =>
        !value ||
        value.type.includes("csv") ||
        value.type.match("application/vnd.ms-excel") ||
        "Se requiere archivo de tipo csv",
    ],

    formatDate,
  }),
  created() {
    this.getCuotas();
  },

  methods: {
    initialize(inst) {
      var self = this;
      // const pagostipos_items = JSON.parse(JSON.stringify(this.desserts));
      inst.map(function(element) {
        let array_nombres = element.descripcion.split(" ");
        if (array_nombres.length > 1) {
          var max = 0;
          var maxString = "";
          array_nombres.forEach(function(val) {
            if (typeof val == "string" && val.length > max) {
              max = val.length;
              maxString = val;
            }
          }); // Fin del foreach
          element.nombre = maxString;
        } // fin del if
        else {
          if (array_nombres[0].toUpperCase() == "TELCO") {
            element.nombre = "GENERAL";
            self.installment = element;
          } else {
            element.nombre = array_nombres[0];
          }
        }
        return element;
      }); // fin de funcion map
      return inst;
    },
    getCuotas() {
      PlanCuotas.getPlanCuotas()
        .then((response) => {
          this.plan_cuotas = this.initialize(response.data);
          this.medio_installment_id = response.data[0].mediopagoinstallments_id;
          this.getInfoInstallments(this.medio_installment_id);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    getInfoInstallments(p_medio_install_id) {
      const params = {
        MedioInstallmentId: p_medio_install_id,
      };
      PlanCuotas.getInfoPlanCuotas(params)
        .then((response) => {
          this.plan_cuotas_info = response.data.data;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    changeInstallment(installment) {
      this.medio_installment_id = installment.mediopagoinstallments_id;
      this.getInfoInstallments(this.medio_installment_id);
    },
    enviar_file() {
      if (!this.backoffice_file) {
        this.snackbar = true;
        return;
      }

      const formData = new FormData();
      formData.append("file", this.backoffice_file);
      formData.append(
        "InstallmentsId",
        this.installment.mediopagoinstallments_id
      ); // Es el id del medio de pago installment y no el id del Installment
      formData.append("VigenciaDesde", this.date); // la fecha debe ir en formato que acepte el endpoint

      this.procensando_datos = true;
      Archivos.uploadCsvInstallments(formData)
        .then((response) => {
          let dataRes = response.data;
          if (dataRes.status) {
            this.$toastr.s("El archivo fue subido exitosamente.");
            //reset form
            this.backoffice_file = null;
            this.date = moment().format("YYYY-MM-DD");

            this.procensando_datos = false;
          }
        })
        .catch(() => {
          this.$toastr.e("Error en el proceso de subir archivo.");
          //reset form
          this.backoffice_file = null;
          this.date = moment().format("YYYY-MM-DD");

          this.procensando_datos = false;
        });
    },
  },
};
</script>
