import axios from 'axios';
import './axiosInterceptor';
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const PlanCuotas = {
  getPlanCuotas() {
    return axios.get(PASARELA + "/administracion/plan-cuotas")
  },
  getInfoPlanCuotas(params) {
    return axios.get(PASARELA + "/administracion/plan-cuotas-info", { params })
  }
}