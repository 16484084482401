<template>
  <LayoutPage title="Archivos">
    <v-tabs v-model="tab">
      <v-tab href="#conciliaciones">
        <v-icon class="mr-2">mdi-text-box</v-icon>
        Prisma conciliaciones
      </v-tab>
      <v-tab href="#plan-cuotas">
        <v-icon class="mr-2">mdi-file-document-check-outline</v-icon>
        Plan de cuotas
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="conciliaciones"><ArchivosOffline /></v-tab-item>
      <v-tab-item value="plan-cuotas"><ArchivosInstallments /></v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>
<script>
import LayoutPage from "../../../components/LayoutPage.vue";
import ArchivosOffline from "@/components/Administrar/ArchivosOffline.vue";
import ArchivosInstallments from "@/components/Administrar/ArchivosInstallments.vue";

export default {
  name: "IndexArchivos",
  components: {
    ArchivosOffline,
    ArchivosInstallments,
    LayoutPage,
  },

  data: () => ({ tab: "" }),
};
</script>
<style scoped>
.card .card-title {
  text-align: center;
}
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #00b1eb;
  box-shadow: 0 5px 20px 0px rgb(0 0 0 / 20%),
    0 13px 24px -11px rgb(17 82 40 / 60%);
}
</style>
