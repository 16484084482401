<template>
  <v-row class="mt-5">
    <v-col cols="12" lg="6" class="pr-10">
      <!-- LOADING GENERAL DEL COMPONENTE -->

      <v-card class=" my-5 elevation-0 border py-5">
        <v-card-title
          class="align-end font-weight-bold body-1"
          style="font-family: 'Montserrat' !important;"
        >
          Archivos de Conciliacion para Prisma
        </v-card-title>

        <v-card-text>
          <!-- FORMULARIO PARA SUBIR EL ARCHIVO -->
          <v-form ref="form" v-model="valid" lazy-validation class="ma-3">
            <!-- Ingreso de archivo -->
            <v-file-input
              v-model="backoffice_file"
              accept="text/*"
              label="Nombre del Archivo"
              :rules="inputRules"
              show-size
              class="mb-8"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <!-- Ingreso de fecha -->
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              dark
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Fecha Vigencia Desde"
                  prepend-icon="today"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="menu = false"
                no-title
                locale="es-es"
                show-current="false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <!-- BOTON ENVIAR ARCHIVO -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!valid"
            color="green"
            text
            @click="enviar_file()"
            class="font-weight-bold"
            style="font-family: 'Montserrat';"
            :loading="procensando_datos"
          >
            Enviar
          </v-btn>
        </v-card-actions>

        <v-snackbar v-model="snackbar" :timeout="2000">
          <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large> file_upload_off </v-icon>
            <h5 align="center">Seleccione un archivo válido por favor</h5>
          </v-layout>
        </v-snackbar>
      </v-card>
    </v-col>

    <v-col cols="12" lg="6">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
        filled
        class="mb-8"
      ></v-text-field>
      <v-data-table
        :search="search"
        :headers="headers"
        :items="desserts"
        :loading="cargando || procensando_datos"
        loading-text="Cargando... Espere por favor"
        no-results-text="No se encontraron registros"
        no-data-text="No se encontraron registros"
        class="elevation-0 trow border"
        :items-per-page="rowsPerPage"
        hide-default-footer
      >
        <!-- NO DATA AND RESULTS MESSAGES -->
        <template v-slot:no-results>
          <v-alert color="warning" icon="warning">
            No se encontraron registros
          </v-alert>
        </template>
        <!-- <template v-slot:>
                    <v-alert color="purple lighten-3" icon="warning">
                      Obteniendo registros... aguarde por favor
                    </v-alert>
                  </template> -->

        <template v-slot:footer>
          <v-row class="no-gutters mt-2 mx-2">
            <v-flex xs12>
              <span>Mostrar</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn dark text color="primary" class="ml-2" v-on="on">
                    {{ rowsPerPage }}
                    <v-icon>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in rowsPerPageArray"
                    :key="index"
                    @click="updateRowsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </v-row>
        </template>
      </v-data-table>
      <!-- END DATA-TABLE DE ARCHIVOS -->

      <div class="text-center pt-2">
        <v-pagination
          :value="paginaActual"
          :length="total"
          :total-visible="visible"
          @input="onPageChange($event)"
        ></v-pagination>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import moment from "moment";
import { Archivos } from "../../services/archivos.js";

export default {
  name: "ArchivosOffline",
  components: {},
  data: () => ({
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    total: 0,
    visible: 5,
    page: 1,
    rowsPerPage: 10,
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    desserts: [],
    headers: [
      { text: "Nombre de Archivo", value: "NombreArchivo" },
      { text: "Fecha de Subida", value: "FechaSubida", sortable: true },
    ],
    search: "",
    cargando: false,
    snackbar: false,
    procensando_datos: false,
    valid: false,
    backoffice_file: null,
    inputRules: [
      (value) =>
        !value ||
        value.name.toUpperCase().includes("PX") ||
        value.name.toUpperCase().includes("MX") ||
        value.name.toUpperCase().includes("4894") ||
        "El archivo no es válido. Debe ser PX, MX o .4894",
    ],
  }),
  created() {
    this.getdatos();
  },
  computed: {
    paginaActual() {
      return this.page;
    },
  },
  methods: {
    // paginar datatable
    onPageChange(pagina) {
      this.page = pagina;
      this.getdatos();
    },
    // actualizar numero de registros por pagina
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.page = 1;
      this.getdatos();
    },
    getdatos: function() {
      const params = {
        Number: this.page,
        Size: this.rowsPerPage,
      };
      this.procensando_datos = true;
      Archivos.getArchivosSubidos(params)
        .then((response) => {
          if (response.status == 204) {
            this.desserts = [];
            this.page = 1;
            this.total = 0;
          }
          if (response.status == 200) {
            this.desserts = response.data.data.data;
            this.page = response.data.data.meta.page.currentPage;
            this.total = response.data.data.meta.page.lastPage;
          }

          this.procensando_datos = false;
          this.cargando = false;
        })
        .catch((error) => {
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
          this.procensando_datos = false;
          this.cargando = false;
        });
    },
    enviar_file() {
      if (!this.backoffice_file) {
        this.snackbar = true;
        return;
      }
      const dmyDate = moment(this.date).format("DD-MM-YYYY");
      // reemplazar replace quitar dash de la fecha
      let fecha = dmyDate.replace(/-/g, "");
      const formData = new FormData();
      formData.append("file", this.backoffice_file);
      formData.append("fecha", fecha);
      this.procensando_datos = true;
      Archivos.uploadFile(formData)
        .then((response) => {
          let dataRes = response.data;
          if (dataRes.status) {
            this.$toastr.s("El archivo fue subido exitosamente.");
            //reset form
            this.date = moment().format("YYYY-MM-DD");
            this.backoffice_file = null;
          }
        })
        .catch(() => {
          this.$toastr.e("Error en el proceso de subir archivo.");
          //reset form
          this.date = moment().format("YYYY-MM-DD");
          this.backoffice_file = null;
        })
        .finally(() => (this.procensando_datos = false));
    },
  },
};
</script>
<style scoped></style>
